<template>
  <div>
    <div class="navbar bg-color fixed-nav" style="z-index: 1000; box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.75);">
      <div style="position: relative; display: flex; justify-content: space-between; height: 100px; align-items: center; width: 90%; margin: auto;">
        <div style="display: flex; align-items: center;">
          <v-img
            max-height="45"
            max-width="285"
            class="mr-4"
            style="cursor: pointer;"
            src="@/assets/logos/Logotipo_Dos tintas.png"
            alt="proplat-logo-white"
            @click="scrollToSection('Home')"
          ></v-img>
          <div class="hidden-md-and-down">
            <v-btn text @click="scrollToSection('Home')" class="nav-link" style="color: #193D6E; font-family: 'montserrat-bold';">{{ texts.FooterLanding.home }}</v-btn>
            <v-btn text @click="scrollToSection('About')" class="nav-link" style="color: #193D6E; font-family: 'montserrat-bold';">{{ texts.FooterLanding.aboutUs }}</v-btn>
          </div>
        </div>
        
        <div style="display: flex; align-items: center; justify-content: space-around;"> 
          <div class="hidden-md-and-down" style="display: flex; align-items: center;">
            <v-menu offset-y class="mr-4">
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-3 mr-6" style="display: flex; cursor: pointer;" @click="home" v-bind="attrs" v-on="on">
                  <img
                    v-if="selectLanguage == 'sp'"
                    style="cursor: pointer;"
                    src="@/assets/images/Flags_mex.svg"
                    alt="proplat-logo-white"
                  />
                  <img
                    v-else
                    style="cursor: pointer;"
                    src="@/assets/images/Flags_usa.svg"
                    alt="proplat-logo-white"
                  />
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>
              <language-component style="background-color: white; padding: 15px;"></language-component>
            </v-menu>
            <v-btn style="border-radius: 20px; padding-right: 30px !important; padding-left: 30px !important; font-family: 'montserrat-semibold';" elevation="9" text @click="redirectToLogin" class="Register-button">{{ texts.FooterLanding.login }}</v-btn>
          </div>
          <div class="d-lg-none" style="display: flex; align-items: center;">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div style="display: flex; cursor: pointer;" @click="home" v-bind="attrs" v-on="on">
                  <img
                    v-if="selectLanguage == 'sp'"
                    style="cursor: pointer;"
                    src="@/assets/images/mexico.png"
                    alt="proplat-logo-white"
                  />
                  <img
                    v-else
                    style="cursor: pointer;"
                    src="@/assets/images/US.svg"
                    alt="proplat-logo-white"
                  />
                  <v-icon>mdi-menu-down</v-icon>
                </div>
              </template>
              <language-component style="background-color: black; padding: 15px;"></language-component>
            </v-menu>
            <span class="nav-divider"></span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showDialog = true" icon v-bind="attrs" v-on="on"><v-icon large>mdi-menu</v-icon></v-btn>
              </template>
              <div style="display: flex; flex-direction: column; background-color: white;" class="pa-6">
                <v-btn text @click="scrollToSection('Home')" class="nav-link" style="color: #193D6E; font-family: 'montserrat-bold';">{{ texts.general.home }}</v-btn>
                <v-btn text @click="scrollToSection('About')" class="nav-link" style="color: #193D6E; font-family: 'montserrat-bold';">{{ texts.general.aboutUs }}</v-btn>
              </div>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-container" style="padding-top: 100px;">
      <section id="Home" class="home-section">
        <v-container fluid class="hero-section">
          <v-row class="align-center flex-column-reverse flex-md-row">
            <v-col cols="12" md="6" class="d-flex justify-center align-center semicircle-bg">
              <v-img 
                src="../../assets/Landing/Phones.png" 
                max-width="100%" 
                max-height="950px"
                contain
                class="left-image"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6" class="text-section">
              <v-img 
                src="../../assets/logos/Logotipo_Dos tintas.png"
                class="logo-img"
                contain
              ></v-img>
              <h1 class="hero-title">{{ selectLanguage === 'sp' ? 'La conexión entre el mundo de las' : 'The connection between the'}} <span class="highlight">{{ selectLanguage === 'sp' ? 'divisas ' : 'currency world '}}</span>{{selectLanguage === 'sp' ? ' y el' : 'and' }} <span class="highlight">{{ selectLanguage === 'sp' ? 'produce' : 'produce'}}</span></h1>
              <p class="hero-subtitle">{{ selectLanguage === 'sp' ? 'Controla tus transacciones internacionales sin comisiones y disfruta de nuestra plataforma.' : 'Control your international transactions without fees and enjoy our platform.'}}</p>

              <div class="email-container pa-0">
                <v-text-field
                  v-model="email"
                  :placeholder="selectLanguage === 'sp' ? 'Ingresa tu e-mail' : 'Enter your email'"
                  :rules="emailRules"
                  class="custom-input pl-0"
                  dense
                  solo
                  hide-details
                  background-color="transparent"
                ></v-text-field>

                <!-- Botón estilizado -->
                <v-btn
                  class="custom-button"
                  style="margin-left: -45px; padding: 19px 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);"
                  @click="validateAndSend"
                  :disabled="!email || emailRules.some(rule => rule(email) !== true)"
                >
                  {{ selectLanguage === 'sp' ? 'Conocer más' : 'Learn more' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <!-- <section id="kpis">
        <v-container class="stats-container">
          <v-card class="stats-card" elevation="0">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" sm="3" class="stat-item">
                <h2 class="stat-number">+20</h2>
                <p class="stat-text">Lorem ipsum dolor</p>
              </v-col>

              <v-divider vertical class="hidden-xs-only"></v-divider>

              <v-col cols="12" sm="3" class="stat-item">
                <h2 class="stat-number">+100k</h2>
                <p class="stat-text">Lorem ipsum dolor</p>
              </v-col>

              <v-divider vertical class="hidden-xs-only"></v-divider>

              <v-col cols="12" sm="3" class="stat-item">
                <h2 class="stat-number">300</h2>
                <p class="stat-text">Lorem ipsum dolor</p>
              </v-col>

              <v-divider vertical class="hidden-xs-only"></v-divider>

              <v-col cols="12" sm="3" class="stat-item">
                <h2 class="stat-number">+80.6</h2>
                <p class="stat-text">Lorem ipsum dolor</p>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </section> -->
      <section id="About" class="pa-10">
        <v-container fluid class="feature-section">
          <v-row align="center" class="feature-content">
            <!-- Sección de Texto -->
            <v-col cols="12" md="6">
              <v-card class="feature-card" elevation="0">
                <!-- Logo en lugar de H1 -->
                <v-img 
                  src="../../assets/logos/Logotipo_Negativo.png" 
                  max-height="200"
                  max-width="500"
                  contain
                  style="border-radius: 0px;"
                  class="feature-logo"
                ></v-img>

                <ul class="feature-list">
                  <li><strong>{{selectLanguage === 'sp' ? 'Diseñado para el produce:' : 'Designed for the Produce Industry:'}}</strong>{{ selectLanguage === 'sp' ? ' Fresher es un dashboard financiero creado específicamente para la industria del produce, entendiendo tus desafíos diarios.' : ' Fresher is a financial dashboard specifically built for the produce sector, understanding its daily challenges.'}}</li>
                  <li><strong>{{selectLanguage === 'sp' ? 'Tranquilidad financiera:' : 'Financial Peace of Mind:'}}</strong>{{ selectLanguage === 'sp' ? ' Te ayuda a mantener la estabilidad de tu negocio frente a las fluctuaciones cambiarias diarias.' : ' It helps businesses maintain stability amid daily currency fluctuations.' }}</li>
                  <li><strong>{{selectLanguage === 'sp' ? 'Éxito garantizado:' : 'Guaranteed Success:'}}</strong>{{selectLanguage === 'sp' ? ' Integra la relación crítica entre el FX y el produce para impulsar el éxito de tu negocio.' : ' It seamlessly integrates the critical relationship between FX and the produce industry to drive business growth.'}}</li>
                </ul>
              </v-card>
            </v-col>

            <!-- Espacio para Imagen del Dashboard -->
            <v-col cols="12" md="6" class="image-container">
              <v-img 
                src="../../assets/Landing/Dashboard.png" 
                contain 
                max-width="100%" 
                class="feature-image"
                style="border-radius: 16px;"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="Why" style="background: linear-gradient(to bottom, #f2f2f4 0%, #DBE7F3 50%, #F8F8F8 100%);">
        <v-container class="why-fresher-section">
          <!-- Título y subtítulo -->
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h2 class="section-title" style="font-family: 'montserrat-bold;">{{selectLanguage === 'sp' ? '¿Por qué FRESHER?' : 'Why fresher?'}}</h2>
              <p class="section-subtitle">
                {{ selectLanguage === 'sp' ? 'Analiza, controla y decide cuándo es el mejor momento para transaccionar divisas.' : 'Analyze, control, and decide when is the best time to transact currencies.'}}
              </p>
            </v-col>
          </v-row>

          <!-- Cuadros de beneficios -->
          <v-row justify="center" class="benefits-container">
            <v-col cols="12" md="4">
              <v-card class="benefit-card">
                <div class="benefit-icon">
                  <v-img class="" src="../../assets/Landing/sun.svg" width="35" height="35" contain></v-img>
                </div>
                <p class="mb-0" style="font-family: 'montserrat-semibold';">{{ selectLanguage === 'sp' ? 'Transacción de divisas 24/7' : '24/7 currency transaction'}}</p>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card class="benefit-card">
                <div class="benefit-icon">
                  <v-img src="../../assets/Landing/shield.svg" width="35" height="35" contain></v-img>
                </div>
                <p class="mb-0" style="font-family: 'montserrat-semibold';">{{selectLanguage === 'sp' ? 'Solicitud de financiamiento' : 'Financing Request'}}</p>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card class="benefit-card">
                <div class="benefit-icon">
                  <v-img src="../../assets/Landing/world.svg" width="35" height="35" contain></v-img>
                </div>
                <p class="mb-0" style="font-family: 'montserrat-semibold';">{{selectLanguage === 'sp' ? 'Pagos internacionales sin comisiones' : 'International payments without fees'}}</p>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card class="benefit-card">
                <div class="benefit-icon">
                  <v-img src="../../assets/Landing/info.svg" width="35" height="35" contain></v-img>
                </div>
                <p class="mb-0" style="font-family: 'montserrat-semibold';">{{selectLanguage === 'sp' ? 'Información y análisis para toma de decisiones' : 'Information and analysis for decision-making'}}</p>
              </v-card>
            </v-col>
          </v-row>

          <!-- Video -->
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-responsive aspect-ratio="16/9" class="video-container" style="border-radius: 40px;">
                <video autoplay muted loop playsinline controls class="video" style="border-radius: 30px;">
                  <source src="../../assets/images/Video_Fresher.mp4" type="video/mp4">
                  Tu navegador no soporta el video.
                </video>
              </v-responsive>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="Mobile" style="background: linear-gradient(to bottom, #F8F8F8 0%, #DBE7F3 50%, #f2f2f4 100%);">
        <v-container class="payment-section">
          <v-row align="center">
            <!-- Columna de Texto -->
            <v-col cols="12" md="6" class="text-container">
              <p class="text-highlight-mobile" style="font-family: 'montserrat-bold;">{{selectLanguage === 'sp' ? '¡Convierte y paga al instante!' : 'Convert and Pay Instantly!'}}</p>
              <h2 class="main-title-mobile" style="font-family: 'montserrat-bold;">
                {{selectLanguage === 'sp' ? 'Lleva el control de tus pagos en una misma herramienta' : 'Manage All Your Payments in One Tool'}}
              </h2>
              <p class="description-mobile">
                {{selectLanguage === 'sp' ? 'Analiza tus movimientos y sus rendimientos para tomar las mejores decisiones.' : 'Analyze Transactions and Performance to Make the Best Decisions'}}
              </p>
            </v-col>

            <!-- Columna de Imagen -->
            <v-col cols="12" md="6" class="image-container">
              <v-img
                src="../../assets/Landing/Group 39237.png"
                contain
                max-width="100%"
                class="app-image"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="Coins" style="background: linear-gradient(to bottom, #F2F2F4 0%, #BDD2E8 50%, #f2f2f4 100%); padding-top: 20px;">
        <v-container class="gif-section pt-15">
          <v-row align="center">
            <!-- Columna de GIF -->
            <v-col cols="12" md="6" class="gif-container">
              <v-img
                src="../../assets/Landing/coin.gif"
                contain
                max-width="100%"
                class="gif-image"
              ></v-img>
            </v-col>

            <!-- Columna de Texto -->
            <v-col cols="12" md="6" class="text-container-coin">
              <h2 class="main-title-mobile">
                {{selectLanguage === 'sp' ? 'Gana Recompensas con ' : 'Earn Rewards with '}}
                <span class="highlight">{{selectLanguage === 'sp' ? 'Fresher Coins ' : 'Fresher Coins '}}</span>
              </h2>
              <p class="description-mobile">
                {{selectLanguage === 'sp' ? 'En Fresher Pay, cada transacción te acerca a increíbles beneficios. Con Fresher Coins, nuestras monedas de recompensa, acumulas puntos mientras usas la plataforma y los canjeas por premios exclusivos.' : 'At Fresher Pay, every transaction brings you closer to amazing benefits. With Fresher Coins, our reward currency, you accumulate points while using the platform and redeem them for exclusive rewards.'}}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="Faq">
        <v-container class="faq-container">
          <h2 class="faq-title"> {{ selectLanguage === 'sp' ? 'Preguntas Frecuentes' : 'Frequently Asked Questions'}}</h2>

          <v-expansion-panels class="faq-list">
            <v-expansion-panel
              v-for="(faq, index) in faqs"
              :key="index"
              class="faq-item"
              style="box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); margin-bottom: 20px;"
              @change="togglePanel(index)"
            >
              <v-expansion-panel-header class="faq-header">
                <span class="faq-question">{{ selectLanguage === 'sp' ? faq.questionMX : faq.questionEN }}</span>
                <template v-slot:actions>
                  <v-img v-if="panelActive[index]" src="../../assets/Landing/faq-active.png" style="width: 30px;"></v-img>
                  <div v-else style="box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); width: 30px; height: 30px; border-radius: 20px; display: flex; ">
                    <v-img src="../../assets/Landing/faq-disabled.svg" contain style="margin: 10px 0px"></v-img>
                  </div>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="faq-content" style="text-align: start; padding: 9px !important">
                {{ selectLanguage === 'sp' ? faq.answerMX : faq.answerEN }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </section>
      <!-- <HomeBannerLandingLayout :texts="texts" id="Home"/>
      <HomeAboutLayout :texts="texts" style="padding-bottom: 80px; display: flex;" id="About"/>
      <HomeWhyLayout :selectLanguage="selectLanguage" :texts="texts" id="Why" style="margin-top: -7px;"/>
      <HomeRegisterBanner :selectLanguage="selectLanguage" :texts="texts" id="RegisterB"/>
      <HomeVideoSection :selectLanguage="selectLanguage" :texts="texts" id="HomeVideoSection"/>
      <HomeProviderLayout :selectLanguage="selectLanguage" :texts="texts" id="Providers"/>  -->
    </div>
    <footerLanding-component :texts="texts"/>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      //VARIABLES
      texts: "",
      panelActive: {},
      email: "",
      faqs: [
        {
          questionMX: "¿Cómo funciona Fresher y cuánto cuesta?",
          answerMX: "Fresher es una plataforma diseñada para hacer tus transferencias internacionales rápidas, seguras y sin complicaciones. Con nosotros, el dinero se envía en minutos, disponible 24/7, sin necesidad de negociar con bancos y sin ningún costo.",
          questionEN: "How does Fresher work and how much does it cost?",
          answerEN: "Fresher is a platform designed to make your international transfers fast, secure, and hassle-free. With us, money is sent within minutes, available 24/7, with no need to negotiate with banks and at no cost.",
        },
        {
          questionMX: "¿Qué se necesita para abrir la cuenta?",
          answerMX: "Se debe cumplir con un proceso de KYC que incluye una lista de requerimientos específicos para cada tipo de empresa. Existen procedimientos tanto para empresas de Estados Unidos como para empresas mexicanas. Para más información, se recomienda contactar a un ejecutivo de cuenta.",
          questionEN: "What is needed to open the account?",
          answerEN: "A KYC process must be completed, which includes a list of specific requirements for each type of company. There are procedures for both U.S. and Mexican companies. For more information, it is recommended to contact an account executive.",
        },
        {
          questionMX: "¿Puedo utilizar esta cuenta para recibir pagos de mis clientes?",
          answerMX: "Sí, puedes compartir la información de tu cuenta bancaria con tus clientes. Los detalles de la cuenta se encuentran dentro de la plataforma.",
          questionEN: "Can I use this account to receive payments from my clients?",
          answerEN: "Yes, you can share your bank account information with your clients. The account details can be found within the platform.",
        },
        {
          questionMX: "¿Cómo reciben el dinero mis beneficiarios?",
          answerMX: "Los fondos llegan directamente a la cuenta bancaria del destinatario en su país, sin intermediarios innecesarios ni demoras.",
          questionEN: "How do my beneficiaries receive the money?",
          answerEN: "Funds are deposited directly into the recipient’s bank account in their country, with no unnecessary intermediaries or delays.",
        },
        {
          questionMX: "¿Mi información está protegida?",
          answerMX: "Sí. En Fresher, utilizamos tecnología avanzada para proteger tus datos personales y empresariales. Además, todas las transferencias se procesan a través de sistemas conectados con los bancos centrales de cada país, cumpliendo con las normativas y regulaciones locales más estrictas.",
          questionEN: "Is my information protected?",
          answerEN: "Yes. At Fresher, we use advanced technology to safeguard your personal and business data. Additionally, all transfers are processed through systems connected to each country’s central banks, complying with the strictest local regulations and requirements.",
        },
      ],
      emailRules: [
        v => !!v || "El email es obligatorio", // Verifica que el campo no esté vacío
        v => /.+@.+\..+/.test(v) || "El formato del email es inválido", // Verifica formato de email
      ],
    }
  },
  beforeMount() {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const offset = 100; // Píxeles de desplazamiento deseado
        const targetPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    },
    redirectToLogin() {
      this.$router.push("/dashboard").catch(() => {});
    },
    home() {
      this.$router.push("/").catch(() => {});
    },
    togglePanel(index) {
      this.$set(this.panelActive, index, !this.panelActive[index]);
    },
    async sendEmail(email) {
      try {

        const payload = {
          email: email
        };

        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/session/lead`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        });
        
        this.mixSuccess(response.data.message, response.data.status);
        this.email = "";
        return response.data.success;
      } catch (error) {
        this.email = "";
        this.mixError(error.response.data.message, error.response.status);
        return false;
      }
    },
    validateAndSend() {
      if (this.emailRules.every(rule => rule(this.email) === true)) {
        this.sendEmail(this.email);
      }
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #f2f2f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 0px;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.navbar {
  padding: 0px 24px;
}
.nav-link {
  font-family: 'RethinkSans-Regular', sans-serif;
  font-size: 16px;
  color: #0075b8;
}
.navbar .nav-link:hover {
  color: #2D2D2D !important;
}
.nav-divider {
  display: inline-block;
  width: 1px; 
  height: 40px;
  background-color: rgba(0, 117, 184, 0.5);
  margin: 0 15px; 
  vertical-align: middle; 
}
.register-button:hover {
  background-color: #2D2D2D !important;
}
.Register-button {
  background-color: #0075B8;
  color: white;
  font-size: 16px !important;
  padding: 10px 24px !important;
  border-radius: 5px;
  font-family: 'RethinkSans-Regular', sans-serif;
}
.menu-arrow {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}
.menu-arrow::before {
  position: absolute;
  content: "";
  top: 0;
  right: 72px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}
.menu-arrow2 {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}
.menu-arrow2::before {
  position: absolute;
  content: "";
  top: 0;
  right: 102px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}
.view-not {
  background-color: #fff5f0;
  padding: 10px;
}
.no-view-not {
  background-color: #ffffff;
  padding: 10px;
}
.text-menu-title {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}
.text-menu-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  white-space: pre-wrap;
  width: 220px;
}
.text-menu-date {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ff8181;
  opacity: 1;
  margin-bottom: 0px;
}

.semicircle-bg {
  background-image: url('../../assets/Landing/semicircle.png'); 
  background-repeat: no-repeat;
  background-size: contain; /* Ajusta la imagen al contenedor sin recortarla */
}

.rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent linear-gradient(180deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 1px solid #ffa77e;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-ext {
  background-color: #151d27;
  border: 1px solid #ff8181;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-int {
  background: transparent linear-gradient(225deg, #ffa77e 0%, #ff8181 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 11px 11px 0px;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-text {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  margin-bottom: 0px;
}
.user-info {
  background-color: #151d27;
  color: #fffc81;
  border-radius: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #fffc81;
}
.height-100 {
  height: 100%;
}
.fixed-nav {
  position: fixed;
  width: 100%;
  z-index: 99999;
  margin-top: 0px;
}
.container-responsive-menu {
  position: absolute;
  width: 100%;
  z-index: 99999;
  padding: 25px 15px;
  height: 100px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
.input-search {
  width: 100%;
  height: 40px;
  background: #1f3042 0% 0% no-repeat padding-box;
  border: 1px solid #3b4c58;
  border-radius: 13px 0px 0px 13px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
}
.input-search::placeholder {
  color: #ffffff;
  font-size: 14px;
}
.input-search:focus-visible {
  outline: none;
}
.button-search {
  min-width: 48px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 13px 13px 0px;
  opacity: 1;
}
.img-logo {
  max-width: 286px;
  max-height: 43px;
}
.img-logo img {
  width: 100%;
}
@supports (object-fit: cover) {
  .img-logo img {
    object-fit: cover;
    object-position: center center;
  }
}
.bg-color {
  height: 100px;
  /* UI Properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  text-align: center;
  background-color: rgba(191, 215, 237);
}
.button-login {
  width: 120px;
  height: 40px !important;
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
  background-color: transparent !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}
.button-register {
  width: 120px;
  height: 40px !important;
  background: transparent linear-gradient(256deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}
.txt-description {
  text-align: left;
  letter-spacing: 0px;
  font-weight: 800;
  color: black;
  opacity: 1;
  font-size: 20px;
}
@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}
@media (min-width: 961px) {
  .hidden-from-desktop {
    display: none;
  }
}
.home-section {
  background: linear-gradient(to bottom, #FFFFFF 0%, #DBE7F3 50%, #f2f2f4 100%);
}
.left-image {
  max-height: 950px; /* Limita la altura de la imagen */
  width: auto; /* Mantiene la proporción */
  object-fit: contain; /* Evita que la imagen se corte */
}
.logo-img {
  max-width: 400px; /* Ajusta según necesidad */
  width: 100%;
  height: 200px;
  margin: auto;
}
.text-content {
  max-width: 670px;
  padding-right: 40px;
  margin: 0 auto;
}
.title {
  font-family: 'montserrat-bold' !important;
  font-size: 60px !important;
}
.subtitle {
  font-family: 'montserrat-semibold';
  font-size: 24px;
  color: #193D6E;
}
.content-right {
  align-self: stretch; /* Hace que la columna derecha tenga la misma altura */
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center;
  align-items: flex-end;
}
.text-content {
  max-width: 650px;
  padding: 0 20px;
  word-wrap: break-word;
  margin: 0; /* Evita que las palabras se desborden */
}
.title {
  font-family: 'montserrat-bold';
  font-size: 60px;
  line-height: 1.1;
  text-align: inherit;
  word-break: break-word;
}
.subtitle {
  margin-top: 30px;
  font-family: 'montserrat-regular';
  font-size: 24px;
  color: #193D6E;
}
/* Ajustes Responsivos */
@media (max-width: 960px) {
  .text-section {
    align-items: center !important; 
    text-align: center !important;
    max-width: 100% !important;
  }

  .title {
    font-size: 18px !important;
  }

  .subtitle {
    font-size: 12px;
  }

  .text-content {
    padding: 0 10px;
    max-width: 90%;
  }
}
.stats-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.stats-card {
  width: 100%;
  max-width: 1100px;
  border-radius: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.stat-item {
  text-align: center;
  padding: 20px;
}

.stat-number {
  font-size: 32px;
  font-weight: bold;
  color: #193D6E;
}

.stat-text {
  font-size: 16px;
  color: #64748B;
}

/* Evita los divisores en pantallas pequeñas */
@media (max-width: 600px) {
  .hidden-xs-only {
    display: none;
  }
}

.feature-section {
  background: #0F3A67 url('../../assets/Landing/Cover produce.png') no-repeat center center;
  background-size: cover;
  padding: 60px 20px; 
  border-radius: 75px;
}

.feature-logo {
  margin-bottom: 20px;
  max-width: 180px;
}
.feature-content {
  max-width: 1300px; /* Limita el ancho máximo */
  margin: 0 auto;
}

.feature-card {
  background: transparent;
  border-radius: 20px;
  padding: 30px;
  color: white;
}

.feature-title {
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  font-size: 18px;
  font-family: 'montserrat-regular';
  line-height: 1.6;
  margin-bottom: 15px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-image {
  max-width: 90%;
  border-radius: 10px;
}

/* Ajustes Responsivos */
@media (max-width: 960px) {
  .feature-card {
    padding: 30px;
  }

  .feature-logo {
    max-width: 150px;
  }

  .feature-list li {
    font-size: 16px;
  }

  .feature-content {
    padding: 0 10px;
  }
}
.why-fresher-section {
  padding: 50px 20px;
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  color: #193D6E;
}

.section-subtitle {
  font-size: 18px;
  color: #64748B;
  font-family: 'montserrat-semibold';
}

.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-bottom: 30px;
}

.benefit-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 12px;
  background: white;
  text-align: center;
  min-height: 100px;
  max-height: 130px;
  height: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  font-size: 32px;
  background-color: #BFD7ED;
  padding: 15px;
  border-radius: 15px;
  margin-right: 20px;
}

.video-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.video {
  width: 100%;
  height: 100%;
  display: block;
}

/* Ajustes responsivos */
@media (max-width: 960px) {
  .section-title {
    font-size: 28px;
  }
  
  .section-subtitle {
    font-size: 16px;
  }

  .benefit-card {
    min-height: 120px;
  }
}

.email-wrapper {
  display: flex;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  overflow: hidden;
}

.email-input {
  flex: 1;
  border-radius: 50px;
}

.email-button {
  border-radius: 50px;
  font-weight: bold;
  text-transform: uppercase;
  height: 48px;
  padding: 0 20px;
}
.payment-section {
  padding: 50px 20px;
}

.text-container {
  text-align: left;
}

.text-highlight {
  color: #2c6ed5;
  font-weight: bold;
  font-size: 18px;
}

.text-highlight-mobile {
  color: #2c6ed5;
  font-weight: bold;
  font-size: 28px;
}

.main-title-mobile {
  font-size: 46px;
  font-weight: bold;
  color: #193D6E;
}

.description-mobile {
  font-size: 28px;
  color: #64748B;
  font-family: 'montserrat-semibold';
  margin-bottom: 20px;
}
.main-title {
  font-size: 36px;
  font-weight: bold;
  color: #193D6E;
}

.description {
  font-size: 18px;
  color: #64748B;
  font-family: 'montserrat-semibold';
  margin-bottom: 20px;
}

.cta-button {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 24px;
}

.app-image {
  max-width: 90%;
}

/* Ajustes Responsivos */
@media (max-width: 960px) {
  .main-title {
    font-size: 28px;
  }
  .description {
    font-size: 16px;
  }
  .main-title-mobile {
    font-size: 28px;
  }
  .description-mobile {
    font-size: 16px;
  }
  .cta-button {
    font-size: 14px;
  }
}

/* Coin */
.gif-section {
  padding: 50px 20px;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gif-image {
  max-width: 90%;
}

.text-container-coin {
  text-align: right;
}

.main-title {
  font-size: 36px;
  font-weight: bold;
  color: #193D6E;
}


.description {
  font-size: 18px;
  color: #64748B;
  margin-bottom: 20px;
}

.cta-button {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 24px;
}

/* Ajustes Responsivos */
@media (max-width: 960px) {
  .main-title {
    font-size: 28px;
  }
  .description {
    font-size: 16px;
  }
  .cta-button {
    font-size: 14px;
  }
}

/* FAQ */
.faq-container {
  max-width: 1100px;
  padding: 40px 20px;
  text-align: center;
}

.faq-title {
  font-size: 48px;
  font-family: 'montserrat-bold';
  color: #193D6E;
  margin-bottom: 30px;
}

.faq-list {
  background: transparent;
}

.faq-item {
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px 0px;
  border-radius: 20px;
}

.faq-header {
  font-size: 22px;
  font-weight: bold;
  color: #193D6E;
  padding: 16px;
  padding-left: 30px;
}

.faq-content {
  font-size: 18px;
  color: #64748B;
  background: white;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border: #193d6d solid 2px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
}

.hero-section {
  padding: 25px 20px;
}

.text-section {
  text-align: end;
  padding: 20px;
  max-width: 30%;
  margin: auto;
}

.hero-title {
  font-size: 48px;
  font-family: 'montserrat-bold';
  color: black;
  line-height: 1.2;
}

.highlight {
  color: #0075B8; /* Color azul de las palabras clave */
}

.hero-subtitle {
  font-size: 24px;
  color: #64748B;
  font-family: 'montserrat-semibold';
  margin-bottom: 20px;
}

.email-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  background: white;
  padding: 0px;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.email-input {
  border: none;
  outline: none;
  padding: 10px;
  flex: 1;
  font-size: 16px;
}

.email-button {
  background: #0075B8;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
}

.image-section {
  display: flex;
  justify-content: center;
}

.hero-image {
  max-width: 100%;
  height: auto;
}

.email-container {
  display: flex;
  align-items: center;
  background: transparent; /* Color de fondo similar al input original */
  border-radius: 30px; /* Bordes redondeados */
  padding: 5px;
  max-width: 500px;
}

/* Campo de entrada de texto */
.custom-input {
  flex: 1;
  border-radius: 30px; /* Redondeado */
  background-color: transparent !important;
  font-size: 16px;
  padding-left: 15px;
}

/* Botón */
.custom-button {
  background: #0075B8 !important;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
}

/* Deshabilitar botón */
.custom-button:disabled {
  background-color: #ccd0d6 !important;
  color: #b0b0b0;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #ccd0d6 !important;
}

</style>