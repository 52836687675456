var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{staticStyle:{"background-color":"#193d6e","display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"dark":"","width":"290","app":"","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column","justify-content":"space-between"}},[_c('div',[_c('v-list-item',{attrs:{"title":"My Application","subtitle":"Vuetify"}},[_c('v-img',{staticClass:"my-15",attrs:{"src":require('../assets/logos/Logotipo_Negativo.png')}})],1),_c('v-divider'),_c('v-list',{staticClass:"mx-5"},_vm._l((_vm.sections),function(item){return _c('v-list-group',{key:item.title,attrs:{"active-class":"activeGroup","prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.texts.menu[item.value]))])],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.items),function(child){return _c('v-list-item',{key:child.title,attrs:{"link":"","active-class":"activeItem","to":child.path,"disabled":child.disabled}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.texts.subMenu[child.value]))])],1)],1)}),1)}),1),_c('v-divider')],1),_c('div',[_c('v-divider'),_c('div',{staticClass:"pa-9"},[_c('div',[_c('v-btn',{class:_vm.selectLanguage == 'en'
                    ? 'mon-medium button-select-language-active mb-2'
                    : 'mon-medium button-select-language mb-2',attrs:{"elevation":"0"},on:{"click":function($event){_vm.changeLanguage('en');
                  _vm.$store.commit('setFadeMenu', false);}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/US.svg"),"alt":""}}),_vm._v(" English ")])],1),_c('div',[_c('v-btn',{class:_vm.selectLanguage == 'sp'
                    ? 'mon-medium button-select-language-active mt-2'
                    : 'mon-medium button-select-language mt-2',attrs:{"elevation":"0"},on:{"click":function($event){_vm.changeLanguage('sp');
                  _vm.$store.commit('setFadeMenu', false);}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/MX.svg"),"alt":""}}),_vm._v(" Español ")])],1)]),_c('v-divider'),_c('div',{},[_c('v-btn',{staticClass:"button-session mon-regular px-0",staticStyle:{"font-family":"'montserrat-semibold'","justify-content":"center"},attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-logout ")]),_vm._v(" "+_vm._s(_vm.texts.dashboard.logout)+" ")],1),_c('h5',{staticStyle:{"font-family":"'montserrat-regular'","color":"#ffffff","font-size":"14px","text-align":"center","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.texts.dashboard.version)+" "+_vm._s(_vm.appVersion)+" | "+_vm._s(_vm.currentYear)+" © FRESHER ")]),_c('h5',{staticClass:"pb-3",staticStyle:{"font-family":"'montserrat-regular'","color":"#ffffff","font-size":"14px","text-align":"center","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.texts.dashboard.allRightsReserved)+" ")])],1)],1)])]),_c('v-app-bar',{attrs:{"color":"#ffffff","absolute":"","app":"","height":"83","persistent":""}},[_c('v-toolbar-title',{staticClass:"pa-0",staticStyle:{"font-size":"0.875rem","line-height":"1.25rem","font-weight":"600","display":"flex","align-items":"center"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.currentDateCdmx)+" Ciudad de México, GMT-6 ")])],1)],1)],1)],1),_c('v-spacer'),_c('v-icon',{attrs:{"dark":"","large":"","color":"grey"}},[_vm._v(" mdi-account-circle ")]),_c('v-list',{attrs:{"width":"170px"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.userName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s("Administrador"))])],1)],1)],1)],1),_c('v-main',{staticStyle:{"background-color":"#f9fafd"}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }